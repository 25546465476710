import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import RouterRoot from './router.tsx'
// import * as Sentry from '@sentry/react'

// Sentry.init({
//   beforeSend: (event) => {
//     if (import.meta.env.ENVIRONMENT !== 'production') return null
//     return event
//   },
//   dsn: import.meta.env.SENTRY_DSN,
//   environment: import.meta.env.ENVIRONMENT,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Session Replay
//   replaysOnErrorSampleRate: 1.0, // Capture 100% of the errors
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%.

//   // Tracing
//   tracePropagationTargets: ['localhost', /^\//],

//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
// })

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterRoot />
  </React.StrictMode>,
)
