import { ReactElement, useEffect, useCallback, useState } from 'react'
import MicToggle from '../assets/Mic.svg'
import VideoToggle from '../assets/Video-Toggle.svg'
import Switch from '../assets/Camera-Switch.svg'
import VideoOff from '../assets/VideoOff.svg'
import MicOff from '../assets/MicOff.svg'
import EndCall from '../assets/Call.svg'
import styled, { css } from 'styled-components'
import { useAtom } from 'jotai'
import {
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  LocalVideoTrack,
  useRTCClient,
} from 'agora-rtc-react'
import {
  checkForMultipleCameraAtom,
  devicesAtom,
  joinedCallAtom,
} from '../atoms'
import { useLocation, useNavigate } from 'react-router-dom'

const Wrap = styled.div<{ $joined?: boolean }>`
  width: 100%;

  ${(props) =>
    props.$joined &&
    css`
      position: absolute;
      bottom: 0;
      color: white;
      transform: translateY(-20px);
      z-index: 100;
    `};
`

const VideoWrap = styled.div<{ $joined?: boolean }>`
  display: flex;
  flex: 1;
  flex-flow: column;
  position: relative;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.$joined &&
    css`
      align-items: flex-end;
      justify-content: flex-end;
      padding: 0 20px 0 0;
    `}
`

const VideoElement = styled.div<{ $joined?: boolean; $aspectRatio: number }>`
  aspect-ratio: ${(props) => props.$aspectRatio};
  ${(props) => {
    if (props.$aspectRatio >= 1) {
      return `
        width: 100%;
        max-width:  ${props.$joined ? `27vw` : `400px`};
        @media (min-width: 768px) {
          max-width: ${props.$joined ? `22vw` : `400px`};
        }
      `
    }
    if (props.$joined) {
      return `
        width: 100%;
        max-width: 25vw;
        @media (min-width: 768px) {
          max-width: 20vw;
        }
      `
    }
    return `
      height: 32vh;
      @media (min-width: 768px) {
        height: 35vh;
      }
    `
  }}
`

const Controls = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin: 16px auto;
  width: 100%;
  padding: 0 20px;
  max-width: 500px;
`

const button = `text-black rounded-full w-[48px] h-[48px] flex justify-center items-center p-0`

const Video = ({
  localCameraTrack,
  localMicrophoneTrack,
}: {
  localCameraTrack: ICameraVideoTrack
  localMicrophoneTrack: IMicrophoneAudioTrack
}): ReactElement => {
  // navigations
  const navigate = useNavigate()
  const location = useLocation()

  // atoms
  const [joined] = useAtom(joinedCallAtom)
  const [hasMultipleCameras, checkForMultipleCameras] = useAtom(
    checkForMultipleCameraAtom,
  )
  const [devices] = useAtom(devicesAtom)

  // state
  const [videoEnabled, setVideoEnabled] = useState(true)
  const [micEnabled, setMicEnabled] = useState(true)
  const [aspectRatio, setAspectRatio] = useState(16 / 9)
  const [deviceId, setDeviceId] = useState(``)

  // agora states
  const agoraClient = useRTCClient()

  const switchCamera = useCallback(async () => {
    if (!localCameraTrack) return

    try {
      if (!deviceId) {
        const currentTrack = localCameraTrack.getMediaStreamTrack()
        const settings = currentTrack.getSettings()
        setDeviceId(settings.deviceId || devices[0].deviceId)
      }

      const currentIndex = devices.findIndex(
        (device) => device.deviceId === deviceId,
      )
      const nextIndex = (currentIndex + 1) % devices.length
      const nextDevice = devices[nextIndex]

      await agoraClient.unpublish(localCameraTrack)
      await localCameraTrack.setDevice(nextDevice.deviceId)
      await agoraClient.publish(localCameraTrack)

      setDeviceId(nextDevice.deviceId)
    } catch (error) {
      console.error('Error switching camera:', error)
    }
  }, [agoraClient, deviceId, devices, localCameraTrack])

  const endCall = useCallback(async () => {
    try {
      // Leave the channel
      await agoraClient.leave()

      // TODO: better clean up

      localCameraTrack?.close()
      localMicrophoneTrack?.close()

      navigate(`/${location.search}`)
    } catch (error) {
      console.error('Error ending call:', error)
    }
  }, [
    agoraClient,
    localCameraTrack,
    localMicrophoneTrack,
    location.search,
    navigate,
  ])

  const toggleVideo = async () => {
    if (localCameraTrack) {
      if (videoEnabled) {
        await localCameraTrack.setEnabled(false)
        setVideoEnabled(false)
      } else {
        await localCameraTrack.setEnabled(true)
        setVideoEnabled(true)
      }
    }
  }

  const toggleMic = async () => {
    if (localMicrophoneTrack) {
      if (micEnabled) {
        await localMicrophoneTrack.setEnabled(false)
        setMicEnabled(false)
      } else {
        await localMicrophoneTrack.setEnabled(true)
        setMicEnabled(true)
      }
    }
  }

  useEffect(() => {
    if (localCameraTrack) {
      const updateAspectRatio = () => {
        const { width, height, deviceId } = localCameraTrack
          .getMediaStreamTrack()
          .getSettings()

        if (deviceId) {
          setDeviceId(deviceId)
        }

        if (width && height) {
          setAspectRatio(width / height)
          console.log('Aspect ratio:', width / height)
        }
      }

      // first aspect ratio check
      // settimeout is required; doing this immediately doesn't work
      setTimeout(() => {
        updateAspectRatio()
      }, 300)

      // attach listeners if the track is updated
      localCameraTrack.on('track-ended', updateAspectRatio)
      localCameraTrack.on('track-updated', updateAspectRatio)

      return () => {
        // clean up listeners
        localCameraTrack.off('track-ended', updateAspectRatio)
        localCameraTrack.off('track-updated', updateAspectRatio)
      }
    }
  }, [localCameraTrack])

  useEffect(() => {
    if (localCameraTrack) {
      checkForMultipleCameras()
    }
  }, [checkForMultipleCameras, localCameraTrack])

  return (
    <Wrap $joined={joined}>
      <VideoWrap $joined={joined}>
        {localCameraTrack ? (
          <VideoElement $joined={joined} $aspectRatio={aspectRatio}>
            <LocalVideoTrack
              track={localCameraTrack}
              play={true}
              videoPlayerConfig={{ mirror: true, fit: 'contain' }}
            />
          </VideoElement>
        ) : (
          <h2>Loading...</h2>
        )}
      </VideoWrap>
      <Controls>
        {joined && (
          <button onClick={endCall} className={`${button} bg-[#BF002F]`}>
            <img src={EndCall} alt="Video" width={32} />
          </button>
        )}

        <button
          onClick={toggleVideo}
          className={`${button} ${!videoEnabled ? `bg-gray-400` : `bg-white`}`}
        >
          {!videoEnabled ? (
            <img src={VideoOff} alt="Video" width={32} />
          ) : (
            <img src={VideoToggle} alt="Video" width={32} />
          )}
        </button>

        <button
          onClick={toggleMic}
          className={`${button} ${!micEnabled ? `bg-gray-400` : 'bg-white'}`}
        >
          {!micEnabled ? (
            <img src={MicOff} alt="Microphone" width={32} />
          ) : (
            <img src={MicToggle} alt="Microphone" width={32} />
          )}
        </button>

        {/* temporarily disabled until we fix switch camera */}
        {false && hasMultipleCameras && (
          <button onClick={switchCamera} className={`${button} bg-white`}>
            <img src={Switch} alt="Microphone" width={32} />
          </button>
        )}
      </Controls>
    </Wrap>
  )
}

export { Video }
