import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App'

import { Start } from './screens/Start'
import { Call } from './screens/Call'
import { AgoraRTCProvider } from 'agora-rtc-react'
import { useAtom } from 'jotai'
import { agoraClientAtom } from './atoms'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <Start />,
      },
      {
        path: 'call',
        element: <Call />,
      },
    ],
  },
])

const RouterRoot = () => {
  const [agoraClient] = useAtom(agoraClientAtom)

  return (
    <AgoraRTCProvider client={agoraClient}>
      <RouterProvider router={router} />
    </AgoraRTCProvider>
  )
}

export default RouterRoot
