import AgoraRTC from 'agora-rtc-react'
import { atom } from 'jotai'

const joinedCallAtom = atom(false)

const getAppointmentIdFromURL = () => {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search)
    return params.get('appointmentId')
  }
  return ``
}

// const channelAtom = atom(``)
const channelAtom = atom(getAppointmentIdFromURL())
const agoraTokenAtom = atom<string | null>(null)

const agoraClientAtom = atom(
  AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' }),
)

const randomUid = () => {
  return Math.floor(Math.random() * 10000) + 1
}

const uidAtom = atom(randomUid())

const rtcPropsAtom = atom((get) => ({
  appid: import.meta.env.AGORA_APP_ID,
  channel: get(channelAtom),
  token: get(agoraTokenAtom),
  uid: get(uidAtom),
}))

const hasMultipleCamerasAtom = atom(false)
const devicesAtom = atom<MediaDeviceInfo[]>([])

const checkForMultipleCameraAtom = atom(
  (get) => get(hasMultipleCamerasAtom),
  async (_, set) => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices()
      const videoInputDevices = devices.filter(
        (device) => device.kind === 'videoinput',
      )

      console.log('Devices:', devices)

      if (videoInputDevices.length > 1) {
        set(hasMultipleCamerasAtom, true)

        const filteredDevices = devices.filter(
          (device) =>
            device.kind === 'videoinput' &&
            (device.label === 'Front Camera' || device.label === 'Back Camera'),
        )

        console.log('Filtered devices:', filteredDevices)

        set(devicesAtom, filteredDevices)
      }
    } catch (e) {
      console.error('Error checking for media devices:', e)
    }
  },
)

export {
  channelAtom,
  agoraTokenAtom,
  agoraClientAtom,
  joinedCallAtom,
  rtcPropsAtom,
  uidAtom,
  checkForMultipleCameraAtom,
  devicesAtom,
  getAppointmentIdFromURL,
}
