import { ReactElement, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useAtom } from 'jotai'
import { agoraTokenAtom, joinedCallAtom, rtcPropsAtom } from '../atoms'
import { Navigate, useLocation } from 'react-router-dom'
import VideoSvg from '../assets/Video.svg'
import { Button } from '../components/Button'
import { Video } from '../compositions/Video'

import {
  useJoin,
  useIsConnected,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  useRemoteUsers,
  RemoteUser,
  useRTCClient,
  IAgoraRTCRemoteUser,
  FetchArgs,
} from 'agora-rtc-react'
import { useViewportHeight } from '../utils'

const Wrap = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  flex-flow: column;
  gap: 24px;
  align-items: center;
`

const Local = styled.div<{ $joined?: boolean }>`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  gap: 24px;
  align-items: center;
  padding: 0 20px;
  z-index: 10;

  @media (min-width: 768px) {
    max-width: 400px;
  }

  ${(props) =>
    props.$joined &&
    css`
      top: auto;
      bottom: 0%;

      @media (min-width: 768px) {
        max-width: 100%;
      }
    `}
`

const Remote = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Waiting = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`

const WaitingText = styled.h2`
  font-weight: bold;
  font-size: 20px;
`

const Status = styled.div`
  position: absolute;
  top: 0%;
  right: 0%;
  transform: translate(-20px, 10px);
  font-size: 12px;
  display: flex;
  flex-flow: column;
  z-index: 20;
`

const Connection = styled.div`
  text-align: right;
`

const DebugMessage = styled.div`
  text-align: right;
`

const Call = (): ReactElement => {
  const location = useLocation()
  const [agoraToken] = useAtom(agoraTokenAtom)
  const [rtcProps] = useAtom(rtcPropsAtom)
  const [joinedCall, setJoinedCall] = useAtom(joinedCallAtom)
  const [message, setMessage] = useState('')

  const height = useViewportHeight()

  // Join the call
  useJoin(rtcProps as FetchArgs, true)

  const isConnected = useIsConnected()

  const { localMicrophoneTrack } = useLocalMicrophoneTrack()
  const { localCameraTrack } = useLocalCameraTrack()

  // usePublish([localMicrophoneTrack, localCameraTrack], joinedCall)
  const remoteUsers = useRemoteUsers()

  const handleJoin = async () => {
    setJoinedCall(true)
    if (localCameraTrack && localMicrophoneTrack) {
      await agoraClient.publish([localMicrophoneTrack, localCameraTrack])
    }
  }

  const agoraClient = useRTCClient()

  useEffect(() => {
    if (!agoraClient) return

    const handleUserJoined = async (user: IAgoraRTCRemoteUser) => {
      console.log('User joined:', user.uid)
      setMessage(`Patient has joined the call`)
    }

    const handleUserLeft = (user: IAgoraRTCRemoteUser, reason: string) => {
      console.log('User left:', user.uid, reason)
      setMessage(`Patient has left the call`)
    }

    const handleUserPublished = async (
      user: IAgoraRTCRemoteUser,
      mediaType: 'audio' | 'video' | 'datachannel',
      // config?: IDataChannelConfig,
    ) => {
      console.log('Patient media published:', user.uid, mediaType)
      setMessage(`Patient turned on ${mediaType}`)
    }

    const handleUserUnpublished = (
      user: IAgoraRTCRemoteUser,
      mediaType: 'audio' | 'video' | 'datachannel',
      // config?: IDataChannelConfig,
    ) => {
      console.log('Patient media unpublished:', user.uid, mediaType)
      setMessage(`Patient turned off ${mediaType}`)
    }

    const handleConnectionStateChange = (
      curState: string,
      prevState: string,
    ) => {
      console.log('Connection state changed:', prevState, '->', curState)
      setMessage(`Connection state changed: ${curState}`)
    }

    agoraClient.on('user-joined', handleUserJoined)
    agoraClient.on('user-left', handleUserLeft)
    agoraClient.on('user-published', handleUserPublished)
    agoraClient.on('user-unpublished', handleUserUnpublished)
    agoraClient.on('connection-state-change', handleConnectionStateChange)

    return () => {
      agoraClient.off('user-joined', handleUserJoined)
      agoraClient.off('user-left', handleUserLeft)
      agoraClient.off('user-published', handleUserPublished)
      agoraClient.off('user-unpublished', handleUserUnpublished)
      agoraClient.off('connection-state-change', handleConnectionStateChange)
    }
  }, [agoraClient])

  if (!agoraToken) {
    return <Navigate to="/" replace state={{ from: location }} />
  }

  return (
    <Wrap style={{ height }}>
      <Status>
        <Connection>
          {isConnected ? '🟢 Connected' : '🔴 Disconnected'}
        </Connection>
        <DebugMessage>{message}</DebugMessage>
      </Status>

      {joinedCall && remoteUsers.length === 0 && (
        <Waiting>
          <WaitingText>
            The meeting will start when the patient joins the call.
          </WaitingText>
        </Waiting>
      )}

      {joinedCall && remoteUsers.length > 0 && (
        <Remote>
          <RemoteUser
            user={remoteUsers[0]}
            videoPlayerConfig={{ fit: 'contain' }}
          >
            {/* <samp className="user-name">{remoteUsers[0].uid}</samp> */}
          </RemoteUser>
        </Remote>
      )}

      <Local $joined={joinedCall}>
        {localCameraTrack && localMicrophoneTrack && (
          <Video
            localCameraTrack={localCameraTrack}
            localMicrophoneTrack={localMicrophoneTrack}
          />
        )}

        {localCameraTrack && localMicrophoneTrack && !joinedCall && (
          <Button onClick={handleJoin}>
            <img src={VideoSvg} width={20} /> Join video call
          </Button>
        )}
      </Local>
    </Wrap>
  )
}

export { Call }
