import { useState, useEffect } from 'react'

const useViewportHeight = (): number => {
  const [height, setHeight] = useState(() => window.innerHeight)

  useEffect(() => {
    setHeight(window.innerHeight)

    const handlers = {
      resize: () => {
        setHeight(window.innerHeight)
      },
      scroll: () => {
        setTimeout(() => {
          const newHeight = window.innerHeight
          setHeight(newHeight)
        }, 100)
      },
      orientationchange: () => {
        setTimeout(() => {
          setHeight(window.innerHeight)
        }, 300)
      },
      visibilitychange: () => {
        if (document.visibilityState === 'visible') {
          setHeight(window.innerHeight)
        }
      },
    }

    window.addEventListener('resize', handlers.resize)
    window.addEventListener('scroll', handlers.scroll)
    window.addEventListener('orientationchange', handlers.orientationchange)
    document.addEventListener('visibilitychange', handlers.visibilitychange)

    return () => {
      window.removeEventListener('resize', handlers.resize)
      window.removeEventListener('scroll', handlers.scroll)
      window.removeEventListener(
        'orientationchange',
        handlers.orientationchange,
      )
      document.removeEventListener(
        'visibilitychange',
        handlers.visibilitychange,
      )
    }
  }, [])

  return height
}

export { useViewportHeight }
