import React, { ReactElement, useCallback } from 'react'
import styled from 'styled-components'
import { Button } from '../components/Button'
import VideoSvg from '../assets/Video.svg'
import { useAtom } from 'jotai'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  channelAtom,
  agoraTokenAtom,
  uidAtom,
  getAppointmentIdFromURL,
} from '../atoms'

const Wrap = styled.div`
  width: 90%;
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  gap: 24px;
  align-items: center;
`

const StyledForm = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 24px;
  align-items: center;
`

const backendApiUrl = import.meta.env.BACKEND_SERVICE

const urlAppointment = getAppointmentIdFromURL()

const Start = (): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()

  const [channel, setChannel] = useAtom(channelAtom)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setAgoraToken] = useAtom(agoraTokenAtom)
  const [uid] = useAtom(uidAtom)

  const handleSubmit = useCallback(async () => {
    if (channel && channel !== '') {
      try {
        const response = await fetch(`${backendApiUrl}/twilio-config/token`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            appointmentCode: channel,
            uid,
          }),
        })

        const { token } = await response.json()
        setAgoraToken(token)
        navigate(`/call${location.search}`)
      } catch (error) {
        console.error(error)
      }
    }
  }, [channel, location.search, navigate, setAgoraToken, uid])

  const handleCodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChannel(event.target.value)
    },
    [setChannel],
  )

  return (
    <Wrap>
      <h3 className="text-2xl font-bold">Enter room code</h3>
      <StyledForm>
        <input
          type="text"
          onChange={handleCodeChange}
          name="appointmentCode"
          defaultValue={urlAppointment || ``}
          className="appearance-none border rounded-full w-full py-2 px-4 text-white leading-tight focus:outline-none"
        />

        <Button onClick={handleSubmit}>
          <img src={VideoSvg} width={20} /> Join video call
        </Button>
      </StyledForm>
    </Wrap>
  )
}

export { Start }
